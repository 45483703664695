import React, { Component } from 'react';
export default class Contacts extends Component {
    render() {
        return (
            <section id="contact">
                <div className="row">
                    <div className="columns contact-details">
                        <h2>Мои контакты</h2>
                        <p className="address">
                            <span>Малюгин Геннадий</span><br />
                            <span>genamalugin@gmail.com</span>
                        </p>
                    </div>
                    <div className="columns download">

                    </div>
                </div> {/* end row */}
            </section>
        );
    }
}